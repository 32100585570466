<template>
    <div class="table-container">
        <b-table
            :fields="tableFields"
            :items="budgetForm"
            responsive="true"
            bordered
            head-variant="light"
            sticky-header="true"
            no-border-collapse
        >
            <template #top-row="data">
                <td></td>
                <td class="td-numbering text-center"></td>
                <td class="td-numbering text-center table-success">1</td>
                <td class="td-numbering text-center table-danger">2</td>
                <td class="td-numbering text-center table-info">3</td>
                <td class="td-numbering"></td>
            </template>
            <template #head(selection)>
                <div class="text-center">
                    <b-form-checkbox
                        v-model="selectAll"
                        @change="onSelectAllChanged"
                        :value="true"
                        :unchecked-value="false" />
                </div>
            </template>
            <template #head(more)>
                <div class="text-center">
                    <i
                        :title="getCommonText('del_selected')"
                        class="icon icon-close table-all-remove"
                        @click="$emit('deleteItem', `${selectAll ? getCommonText('all_cats') : getCommonText('selected_cats')}`)"/>
                </div>
            </template>
            <template #cell(selection)="data">
                <div class="text-center">
                    <b-form-checkbox
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </div>
            </template>
            <template #cell(action)="data">
                <div class="text-center">
                    <button
                        class="table-plus"
                        @click="$emit('setCurrCatName', data.item)"
                        :disabled="!(data.item.name_ru || data.item.name_kk) || data.item.nameUpdating">
                        <i class="icon icon-plus-circle"/>
                    </button>
                </div>
            </template>
            <template #cell(name_ru)="data">
                <div
                    class="column-width-100 bp-category-name">
                    <div>{{ data.item[categoryFieldNames[lng]] }}</div>
                    <i 
                        v-if="variantAttribute"
                        class="icon icon-pencil-edit thin-blue ml-3 pointer icon-18" 
                        @click="$emit('onEditCatName', data.item)"
                    ></i>
                </div>
            </template>
            <template #cell(total)="data">
                <div class="text-right">{{ $n(data.value) }}</div>
            </template>
            <template #cell(files)="data">
                <span
                    class="blue pointer underline"
                    @click="$emit('onAllFilesClick', data.item)"
                >({{data.item.files ? data.item.files.length : 0}})</span>
            </template>
            <template #cell(more)="data">
                <div class="text-center">
                    <i class="icon icon-clear table-remove" v-if="variantAttribute" :disabled="deletingCat" @click="$emit('deleteItem', `${getCommonText('curr_cat')}`, {...data.item, good_type: data.item}, data.index, true)"></i>
                </div>
            </template>
            <template #bottom-row="data">
                <td class="text-right" colspan="3">{{ getCommonText('total') }}</td>
                <td class="text-right">{{ $n(total) }}</td>
                <td colspan="2"></td>
            </template>
        </b-table>
        <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="$emit('addItem')"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'form-02-159-tab1',
    components: {
       
    },

    props: {
        budgetForm: {type: Array, required: true},
        deletingCat: {type: Boolean, required: true},
        getCommonText: {type: Function, required: true},
        isLoad: {type: Boolean, required: true},
        setFormText: {type: Function, required: true},
        variantAttribute: {required: true},
        total: {type: Number, required: true},
        categoryFieldNames: {type: Object, required: true},
        lng: {type: String, required: true}
    },

    data() {
        return {
            selectAll: false
            };
    },

    methods: {
        onSelectAllChanged(val) {
            this.$emit('setSelectAll', val)
        },

        setSelectAll(value) {
            this.selectAll = value;
        }
    },
    
    computed: {
        getFormText() {
            return this.setFormText('form_02_159.');
        },
        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'selection',
                        label: ' '
                    },
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'name_ru',
                        label: this.getCommonText('name')
                    },
                    {
                        key: 'total',
                        label: this.getFormText('expense_sum')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        }
    }
}
</script>

<style scoped>
.table-plus {
    border: none;
}

</style>