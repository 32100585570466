import { Ax } from '@/utils';
import formsHandlerMixin from './forms-handler-mixin';
//  ************************************************** //
//      Миксин для форм c вкладкой Платные Услуги      //
//  ************************************************** //
export default {
    mixins: [formsHandlerMixin],
    mounted() {
        this.setTabsStyle({top: '177px'});
        window.addEventListener('hashchange', () => {
            this.setActiveTab();
            this.resetCatMode();
        });
    },
    data() {
        return {
            activeTabIndex: this.getActiveTab(),

            goodsDataPaid: [],
            initialGoodsDataPaid: {},
            currCategoryPaid: null,
            allFilesPaid: [],
            dictPaid: [],
            selectAllPaid: false,
            selectAllGoodsPaid: false,
            categoryModePaid: true,
            areDoublesFoundPaid: false,
            dataChangedPaid: false,
            isFirstCreation: true,
        }
    },

    watch: {
        isGuMode() {
            this.setTabsStyle()
        },
        
        categoryMode() {
            this.hideTabsWhenDecodeMode(this.categoryMode);
        },

        categoryModePaid() {
            this.hideTabsWhenDecodeMode(this.categoryModePaid);
        },
     }, 

    methods: {
        setCurrCatNamePaid(cat, id) {
            this.currCategoryPaid = cat;
            this.categoryModePaid = false;
            this.loadGoodsDataPaid(cat);
        }, // установка имени текущей категории для модалки с расшифровками
        
        async loadGoodsDataPaid(cat) {
            this.templateTableOverlay = true;
            if (!cat.code) {this.templateTableOverlay = false; return;}
            this.initialGoodsDataPaid = {};
            const newHeader = {...this.header, cat_code: cat.code}
            try {
                const response = await fetch('/api-py/get-budget-request-form-goods-paid/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(newHeader)
                });
                if (response.status === 200) {
                    const values = await response.json();
                    await this.ensTruDataAddingToGoods(values);
                    
                    process.nextTick(() => {
                        values.forEach(val => {
                            this.$set(val, 'isNew', false);
                            this.$set(val, 'rowToSave', false);
                            this.$set(val, 'amountPassVal', true);
                            this.$set(val, 'pricePassVal', true);
                            this.$set(val, 'currGoodDesc', this.ensTruName.filter(ens => ens.code === val.enstru_code)[0]);
                            this.$set(val, 'ensTruName', this.ensTruName.filter(itm => itm['name_'+this.lang] === val.name));
                            this.$set(val, 'itemToDelete', false);

                            this.setTotals(val)
                            // добавлене списка единиц изменрения
                            const uom = [];
                            this.unitsList.filter(item => item.enstru_code === val.enstru_code)
                            .forEach(u => {
                                const unitName = this.getUnitName(u.unit_code);
                                uom.push({name: unitName, code: u.unit_code});
                            })
                            if (uom.length) {
                                this.currUnit = uom.filter(item => item.code === val.unit_code)[0];
                            } else this.currUnit = [];
                            this.$set(val, 'uom', uom);
                            this.initialGoodsDataPaid[val.id] = { ...val, total: val.total };
                        });
                        this.goodsDataPaid = values.sort((a, b) => a.id - b.id);
                    });
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadGoodsDataPaid()`, error.toString());
            }
            this.templateTableOverlay = false;
            this.goodsAreSaving = false;
        }, // загрузка списка расшифровок

        ifAllFieldsFilledPaid() {
            const prevNewItemIdx = this.goodsDataPaid.findIndex(item => item.isNew === true);
            if (prevNewItemIdx !== -1) {
                const prevNewItem = this.goodsDataPaid[prevNewItemIdx];
                if (!prevNewItem.enstru_code) {
                    this.makeToast('warning', this.getCommonText('attention'), this.getCommonText('not_selected'));
                    return false;
                };
            }
            if (this.goodsDataPaid.findIndex(item => item.amountPassVal === false) !== -1) {
                this.makeToast('warning', this.getCommonText('attention'), this.getCommonText('amnt_more_zero'));
                return false;
            }
            if (this.goodsDataPaid.findIndex(item => item.pricePassVal === false) !== -1) {
                this.makeToast('warning', this.getCommonText('attention'), this.getCommonText('cost_more_zero'));
                return false;
            }
            if (prevNewItemIdx !== -1) this.$set(this.goodsDataPaid[prevNewItemIdx], 'isNew', false);
            return true
        }, // проверка заполненности полей у расшифровках

        resetModalPaid(bvModalEvent) {
            if (this.dataChanged && bvModalEvent) {
                bvModalEvent.preventDefault();
                this.onGoodsModalClosePaid();
            } else {
                this.categoryModePaid = true;
                this.goodsDataPaid.splice(0); 
            }
        }, // отчистка 'goods-modal' при закрытии без сохранения

        onGoodsModalClosePaid() {
            this.$bvModal.msgBoxConfirm(
                `${this.getCommonText('unsaved_changes')}!`,
                {
                    title: `${this.getCommonText('unsaved_changes')}!`,
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('continue'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.categoryModePaid = true;
                        this.dataChanged = false;
                        this.goodsDataPaid.splice(0);

                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.getErrText('modal_close'), error.toString());
                });
        },

        fillFilesListPaid(item) {
            this.allFilesPaid.push({...item, row_id: null});
        }, // получение всех файлов из дочернего компонента

        restetFilesListPaid() {
            this.allFilesPaid.splice(0);
        }, //добавил т.к. при перерисовке модуля загрузки файлов появляются дубли

        deleteLinkToFilePaid(fileCode = null) {
            if (!fileCode) {
                this.budgetFormPaid.forEach(item => {
                    this.$set(item, 'files', []);
                });
                return;
            };
            this.budgetFormPaid.forEach(item => {
                const idx = item.files ? item.files.findIndex(file => file === fileCode) : -1;
                if (idx !== -1) {
                    item.files.splice(idx, 1);
                };
            });
        }, // удаление кода файла из списка категории

        triggDownloadFilePaid(filename) {
            this.$refs.filesUpdownPaid.downloadFile(filename);
        }, // инициация скачивания файла

        triggDownloadAllFilesPaid(files) {
            this.$refs.filesUpdownPaid.downloadAllFile(files);
        }, // инициация скачивания файлов

        async updateDatasPaid(onlyCat=false, clearAllFiles = true) {
            if (clearAllFiles) this.allFilesPaid.splice(0);
            await this.loadCategoryDataPaid();
            if (!onlyCat) {
                this.loadGoodsDataPaid(this.currCategoryPaid);
            }
        },

        deleteItemPaid(msg, row = false, index = false) {
            if (!this.variantAttribute || this.isLoad
                || (!row && this.budgetFormPaid.findIndex(itm => itm.itemToDelete) === -1)) return;
            this.currCategoryPaid = row.good_type;

            this.$bvModal.msgBoxConfirm(
                this.getCommonText('del_with_decodes', {msg: msg}),
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    if (value) {
                        this.deletingCat = true;
                        if (row) {
                            if (this.currCategoryPaid) {
                                await this.loadGoodsDataPaid(this.currCategoryPaid);
                            }

                            process.nextTick(async () => {
                                if (row.id >= 0) {
                                    const isHasGoods = this.goodsDataPaid && this.goodsDataPaid.length > 0
                                    await this.deletePaid(row, !isHasGoods);
                                    this.deleteGoodPaid(this.goodsDataPaid, true, true);
                                } else {
                                    this.budgetFormPaid.splice(index, 1);
                                }
                                this.disableDictItmsPaid();
                                this.afterDeletedPaid();
                            });
                        } else {
                            const existingRowsToDel = this.budgetFormPaid.filter(item => item.id >= 0 && item.itemToDelete);
                            this.budgetFormPaid = this.budgetFormPaid.filter(item => !item.itemToDelete);
                            if (!existingRowsToDel.length) {
                                this.selectAllPaid = false;
                            } else {
                                if (['01-141', '01-142', '01-153', '03-141', '02-141'].includes(this.form.code)) { // очистка таблицы -decode, где есть
                                    const existingGoodsToDel = [];
                                    existingRowsToDel.forEach(row => existingGoodsToDel.push(row.category_id));
                                    await this.deleteSeveralGoodsPaid(existingGoodsToDel, 0, true);
                                }

                                await this.deleteSeveralCatsPaid(existingRowsToDel);
                            }
                            this.afterDeletedPaid();
                        }
                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.getCommonText('on_del'), error.toString());
                    this.deletingCat = false;
                })
                .finally(() => {
                    this.deletingCat = false;
                })
        },

        disableDictItmsPaid() {
            // при необходимости подменить в родительском компоненте
        },

        afterDeletedPaid() {
            // при необходимости подменить в родительском компоненте
        },

        async deletePaid() {
            //  на случай, если нужно дополнительно удалить что-то еще. Напр Form01_142
        },

        calcGoodsTotalBeforeDelPaid() {
            // при необходимости заменить в родителе
            return this.budgetFormPaid.length ? this.budgetFormPaid.reduce((acc, sum) => acc + sum[this.getTotalFieldName()], 0) : 0;
        },

        async deleteSeveralGoodsPaid(items, totalDeleting, catDeleting = false) {
            const totalCats = this.calcGoodsTotalBeforeDelPaid();
            let totalVal = totalCats
            if (this.form.code !== '01-153') {
                totalVal = totalVal - totalDeleting;
            }
            const dataToDel = {
                items,
                header: { ...this.header, value: Math.ceil(totalVal) },
                form: this.getFormCode(),
                catDeleting,
            }
            try {
                const response = await fetch('/api-py/delete-several-goods-paid/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataToDel)
                });
                const result = await response.json();
                if (!catDeleting && (response.status === 200) && (result.result === 'success')) {
                    if (!this.goodsDataPaid.filter(itm => itm.id > 0).length) {
                        this.resetModalPaid();
                    }
                    this.updateDatasPaid(true)
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('deleted'));
                }
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
                // return response.status;
            } finally {
                this.disableDictItmsPaid();
                this.dataChanged = false;
                this.deletingCat = false;
                this.selectAllPaid = false;
            }
        },

        // пересчет тотала при удалении нескольких записей
        calcTotalBeforeDelPaid() {
            // при необходимости заменить в родителе
            return this.budgetFormPaid.length ? this.budgetFormPaid.reduce((acc, sum) => acc + sum.total, 0) : 0;
        },

        // удаление нескольких категорий
        async deleteSeveralCatsPaid(items) {
            const totalVal = this.calcTotalBeforeDelPaid();
            const dataToDel = {
                items,
                header: { ...this.header, value: Math.ceil(totalVal) },
                form: this.form.code,
                catIdField: this.getCodeFieldName()
            }
            try {
                const response = await fetch('/api-py/delete-several-cats-paid/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataToDel)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.dataChanged = false;
                    this.updateDatasPaid(true)
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('deleted'));
                }
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
            } finally {
                this.disableDictItmsPaid();
                this.deletingCat = false;
                this.selectAllPaid = false;
            }
        },

        async deleteGoodPaid(item, showMsg = true, isCatDeleting = false) {
            if (!item.length) return;
            this.updateTotalBeforeSavePaid(isCatDeleting, 'totalSaved');
            const dataToDel = {
                item,
                header: this.header,
                form: this.getFormCode()
            }
            try {
                const date = new Date()
                const response = await fetch('/api-py/delete-list-budget-request-form-paid/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataToDel)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (!this.goodsDataPaid.filter(itm => itm.id > 0).length) {
                        this.resetModalPaid();
                    }
                    await this.updateDatasPaid(true);
                    if (showMsg) this.makeToast('success', this.getErrText('msg'), this.getErrText('one_deleted'));
                    this.setDataChanged(false);
                }
                return response.status;
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
                return response.status;
            }
        }, // удаление данных

        showFileUploadModalPaid() {
            this.$refs.filesUpdownPaid.showModalFileUpload();
        }, // вызов окна загрузки файлов

        prepareForSavePaid(event) {
            event.preventDefault();
            if (!this.ifAllFieldsFilledPaid()) return;
            this.goodsAreSaving = true;
            this.updateTotalBeforeSavePaid();
            const values = [];
            for (const row of this.goodsDataPaid) {
                if (!row.rowToSave) continue;
                const item = Object.assign({}, this.header);
                this.fillDataForSavePaid(item, row);
                this.$set(item, 'id', row.id);
                this.$set(item, 'enstru_code', row.enstru_code);
                this.$set(item, 'add_detail', row.add_detail ? row.add_detail : null);
                this.$set(item, 'unit_code', row.unit_code ? row.unit_code : null);
                this.$set(item, 'file_hash', row.file_hash ? row.file_hash : null);
                this.$set(item, 'totalToSave', this.totalPaid);
                values.push(item);
            }
            if (this.budgetFormPaid.find(item => item.checkForDuplicates === true)) {
                this.makeToast('danger', this.getErrText('warning'), `${this.getErrText('not_valid')}. ${this.getErrText('not_saved')}!`);
                this.goodsAreSaving = false;
            }
            else {
                if (values.length && this.variantAttribute) {
                    this.savePaid(values);
                }
                else {
                    this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
                    this.goodsAreSaving = false;
                }
            }
        },

        fillDataForSavePaid(item, row) {
            // при необходимости заменить в соответствии с формой
            this.$set(item, 'good_type', this.currCategoryPaid.code);
            this.$set(item, 'amount', parseInt(row.amount));
            this.$set(item, 'price', parseFloat(row.price));
        }, // заполнение полей при подготовке к сохранению товаров

        async savePaid(values, onlyCats = false, showMsg = true, addHeader = true) {
            this.isLoad = true;
            const dataToSave = {
                values,
            }
            const isHasDecodeTable = ['01-141', '01-142', '01-153', '02-141', '03-141'].includes(this.form.code)
            if (addHeader) dataToSave.header = this.header;
            try {
                const url =  isHasDecodeTable ? '/api-py/save-brform-gkkp-decode-paid/' : '/api-py/save-brform-gkkp-headerinside-paid/';
                
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataToSave)
                });
                const result = await response.json();
                if (response.status === 200) {
                    this.dataChanged = false;
                    this.goodsDataPaid.forEach(good => {
                        if (good.id < 0) this.$set(good, 'id', good.id * -1)
                    })
                    this.updateDatasPaid(onlyCats);
                    this.selectAllPaid = false;
                    if (showMsg) this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                } else {
                    this.goodsAreSaving = false;
                    throw this.getErrText('not_saved');
                }
            } catch (e) {
                this.goodsAreSaving = false;
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        prepareForSaveCatsPaid(form) {
            form.forEach(item => {
                if (item.rowToSave) {
                    this.saveCatsPaid(item);
                };
            })
        },

        updtHeaderPaid(total = this.totalPaid) {
            this.$set(this.header, 'value', total);
        }, // добавление тотала в хедер перед схранением

        async saveCatsPaid(row, saveTotals = true) {
            if (row.newCat || row.rowToSave) {
                this.updtHeaderPaid();
                const values = [{...row, ...this.header}];
                this.budgetFormPaid.forEach(bf => {
                    const isRowShouldBeSaved = this.isCatShouldBeSaved(row, bf); 
                    if (isRowShouldBeSaved) values.push({...bf, ...this.header});
                });
                const dataToSave = {
                    values: values,
                    header: this.header,
                    saveTotals
                }
                
                try {
                    const url = '/api-py/save-brform-gkkp-headerinside-paid/';
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(dataToSave)
                    });
                    const result = await response.json();
                    if (response.status === 200) {
                        this.updateDatasPaid(true);
                    } else {
                        throw this.getErrText('not_saved');
                    }
                } catch (e) {
                    this.makeToast('danger', this.getErrText('warning'), e.toString());
                } finally {
                }
            }
        }, // слхранение категорий

        areThereDoublesPaid() {
            if (this.areDoublesFoundPaid) this.makeToast('warning', this.getErrText('warning'), this.getErrText('doubles'));
            return this.areDoublesFoundPaid
        },

        async delelteAttachedFilePaid(file_name = null, file_type = null) {
            if (!file_name || !file_type) {
                this.deleteLinkToFilePaid();
                return;
            }
            const fileCode = this.stringToUnicode(file_type + file_name);
            try {
                const response = await fetch(`/api-py/delete-linked-file-paid/${fileCode}/${JSON.stringify(this.header)}`);
                if (response.status === 200) {
                    this.deleteLinkToFilePaid(fileCode);
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('msg'), this.getErrText('files', {msg: e.toString()}));
            }
        },

        async onNameChangedPaid(row, categoryFieldNames) {
            const fieldName_ru = categoryFieldNames.ru;
            const fieldName_kk = categoryFieldNames.kk;

            const isNameWasChanged = !row.newCat && (row.oldName_ru !== row[fieldName_ru] || (row.oldName_kk !== row[fieldName_kk]));
            if (row.newCat || isNameWasChanged) {
                this.$set(row, 'nameUdating', true);
                await this.loadGoodsDataPaid(row);
                
                process.nextTick(async () => {
                    if (!this.goodsDataPaid || !this.goodsDataPaid.length) {
                        this.$set(row, 'nameUdating', false);
                        return;
                    }

                    try {
                        this.goodsDataPaid.forEach(item => {
                            this.$set(item, fieldName_ru, row[fieldName_ru]);
                            this.$set(item, fieldName_kk, row[fieldName_kk]);
                        });
                        this.updtHeaderPaid();
                        const dataToSave = {
                            values: this.goodsDataPaid,
                            header: this.header
                        }
                        const url = '/api-py/save-brform-gkkp-headerinside-paid/';
                        const response = await fetch(url, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json;charset=utf-8'
                            },
                            body: JSON.stringify(dataToSave)
                        });
                        const result = await response.json();
                        if ((response.status === 200) && (result.result === 'success')) {
                            this.$set(row, 'oldName_ru', row[fieldName_ru]);
                            this.$set(row, 'oldName_kk', row[fieldName_kk]);
                        }
                        this.$set(row, 'nameUdating', false);
                    } catch (e) {
                        this.makeToast('danger', this.getErrText('unable_save_name'), e.toString());
                        this.$set(row, 'nameUdating', false);
                        this.$set(row, fieldName_ru, row.oldName_ru);
                        this.$set(row, fieldName_kk, row.oldName_kk);
                    }
                })
            }
        }, // сохранение нового наименования в БД

        onChangeNamePaid(newVal, row, fieldName) {
            this.checkForDuplicatesPaid(fieldName)
        }, // поиск дублей в наименовании категорий при ручном вводе

        checkForDuplicatesPaid(fieldName) {
            const existingItems = new Map();
            let doublesFound = false;
            this.budgetFormPaid.forEach((item, idx) => {
                const trimedCurField = item[fieldName].trim().toLowerCase();
                if (existingItems.has(trimedCurField)) {
                    this.budgetFormPaid[existingItems.get(trimedCurField)].checkForDuplicates = true;
                    item.checkForDuplicates = true;
                    doublesFound = true;
                } else {
                    item.checkForDuplicates = false;
                    existingItems.set(trimedCurField, idx)
                }
            });
            this.areDoublesFoundPaid = doublesFound;
        },

        isEditForbiddenPaid(row) {
            return this.areDoublesFoundPaid && !row.checkForDuplicates;
        },

        onAllFilesClickPaid(item) {
            this.$refs.modalAllFilesManagementPaid.showModal(item);
        },

        updateTotalBeforeSavePaid(isCatDeleting = false, prop = 'totalAll') {
            const currTotal = isCatDeleting ? 0 : this.$refs.formTemplatePaid.getTotal()[prop];
            if (currTotal >= 0) {
                for (const cat of this.budgetFormPaid) {
                    if (cat[this.getCodeFieldName()] === this.currCategoryPaid.code) {
                        this.$set(cat, this.getTotalFieldName(), currTotal)
                    }
                }
            }
            this.updtHeaderPaid();
        },

        downloadRepPaid() {
            this.isReportUploading = true;
            this.header.lang = this.$i18n.locale;
            this.header.code_modules = this.moduleCode;
            this.header.isGkkpPaid = this.isGkkpPaid;
            Ax(
                {
                    url: '/api-py/budg_' + this.form.code.replace('-', '_') + '/' + JSON.stringify(this.header),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.getCommonText('form')} ` + this.form.code.replace('-', '_') + `${this.getCommonText('paid_services_shrt')}` + '.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.isReportUploading = false;
                },
                (error) => {
                    this.isReportUploading = false;
                    this.makeToast('danger', `${this.getErrText('bad_request')} downloadRepPaid()`, error.toString());
                }
            );
        },

        setLocNamesPaid() {
            this.goodsDataPaid.forEach(item => {
                this.setGoodName(item);
            })
        },

        setTabsStyle(styles = null) {
            const tabs = this.$el.querySelector('.budget-form_tabs');
            if (tabs) {
                if (styles) {
                    tabs.style.top = styles.top;
                }
                if (this.isGuMode === false) {
                    tabs.style.display = 'flex';
                } else {
                    tabs.style.display = 'none';
                }
            }
        },

        createDictCopy(dict) {
            this.dictPaid = JSON.parse(JSON.stringify(dict));
            this.dictPaid.forEach(row => {
                Object.defineProperty(row, 'name', {
                    get: () => {
                        return row[`name_${this.lng}`];
                    }
                });
            })
        },

        setIsAllDeletePaid(val, data = this.budgetFormPaid) {
            this.selectAllPaid = val;
            data.forEach(item => this.$set(item, 'itemToDelete', val));
        },

        setActiveTab() {
            this.activeTabIndex = this.getActiveTab();
        },

        getActiveTab() {
            const tabMap = {
              'budg': 0,
              'paid': 1,
            };
            return tabMap[this.$route.name] || 0;
        },

        updateRoute(index) {
            const routes = ['budg', 'paid'];
            let currentURL = window.location.href;
            let newURL = currentURL;
            const isUrlHasTabCode = currentURL.indexOf('/paid') !== -1 || currentURL.indexOf('/budg') !== -1
            if (!isUrlHasTabCode) currentURL = currentURL + '/budg';
            switch (index) {
                case 0:
                    newURL = this.replaceLastOccurrence(currentURL, 'paid', routes[index]);
                    break;
                case 1:
                    newURL = this.replaceLastOccurrence(currentURL, 'budg', routes[index]);
                    break;
                default:
                    break;
            }
            // Обновление URL без перезагрузки страницы
            if (newURL !== currentURL) {
                history.pushState({}, '', newURL);
            }
        },

        replaceLastOccurrence(str, searchValue, replaceValue) {
            const lastIndex = str.lastIndexOf(searchValue);
            
            if (lastIndex === -1) {
                return str;
            }
        
            const beforeLastOccurrence = str.substring(0, lastIndex);
            const afterLastOccurrence = str.substring(lastIndex + searchValue.length);
        
            return beforeLastOccurrence + replaceValue + afterLastOccurrence;
        },

        onGoBackBtnClicked(event) {
            if (this.activeTabIndex === 0) this.resetModal(event);
            if (this.activeTabIndex === 1) this.resetModalPaid(event);
        },

        onAddGoodBtnClicked() {
            if (this.activeTabIndex === 0) this.$refs.formTemplate.addItem();
            if (this.activeTabIndex === 1) this.$refs.formTemplatePaid.addItem();
        },

        onSaveGoodBtnClicked(event) {
            if (this.activeTabIndex === 0) this.prepareForSave(event);
            if (this.activeTabIndex === 1) this.prepareForSavePaid(event);
        },

        onAddCategoryClicked() {
            let budgetForm = [];
            if (this.activeTabIndex === 0) budgetForm = this.budgetForm;
            if (this.activeTabIndex === 1) budgetForm = this.budgetFormPaid;
            this.addItem(budgetForm);
        },

        setSelectAll(value) {
            if (this.activeTabIndex === 0) {
                this.selectAll = value;
                this.setIsAllDelete(value);
            }
            if (this.activeTabIndex === 1) {
                this.selectAllPaid = value;
                this.setIsAllDeletePaid(value);
            }
        },

        onAddNewCatPaid() {
            if (this.isNotSavedCatExist) return;
            const modal = this.$refs.setCatNameModalPaid;
            if (modal) modal.showModal();
        },

        onEnterCategoryName() {
            if (this.activeTabIndex === 0) this.onAddNewCat();
            if (this.activeTabIndex === 1) this.onAddNewCatPaid();
        },

        hideTabsWhenDecodeMode(isCatMode) {
            const tabs = this.$el.querySelector('.budget-form_tabs');
            if (isCatMode && !this.isGuMode) {
                tabs.style.display = 'flex';
            } else {
                tabs.style.display = 'none';
            }
        },
        resetCatMode() {
            this.categoryMode = true;
            this.categoryModePaid = true;
        }
    },

    computed: {
        currTabCategoryMode() {
            let isCatMode = this.categoryMode;
            if (this.activeTabIndex === 1) {
                isCatMode = this.categoryModePaid;
            }
            return isCatMode;
        },

        currTabCategoryName() {
            let catName = '';
            if (this.activeTabIndex === 0 && this.currCategory) {
                catName = this.currCategory[`name_${this.lng}`];
            }
            if (this.activeTabIndex === 1 && this.currCategoryPaid) {
                catName = this.currCategoryPaid[`name_${this.lng}`];
            }
            return catName;
        },

        isPaidTab() {
            return this.activeTabIndex === 1;
        },
        
        isNotSavedCatExist() {
            if (this.activeTabIndex === 0) {
                return this.budgetForm.findIndex(item => item.id === -1) !== -1;
            }
            if (this.activeTabIndex === 1) {
                return this.budgetFormPaid.findIndex(item => item.id === -1) !== -1;
            }
        },

        currTabName() {
            return this.isPaidTab ? 'paid' : 'budg';
        },

        decodeTabAdditionalTitle() {
            if (!this.categoryMode) return `(${this.getCommonText('budget_expense')})`;
            if (!this.categoryModePaid) return `(${this.getCommonText('paid_services')})`;
        },
        
        isGkkpPaid() {
            return this.paidGkkpForms.includes(this.form.code);
        },
    }
}